import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

function GetToken() {
  // return localStorage.getItem("IdeationToken");
  return localStorage.getItem("IdeationToken");
}

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: true,
  darkMode: false,
  //URL: "https://localhost:44342",
  URL : "https://testideation.azurewebsites.net",
  //URL : "https://ideationapi-test.azurewebsites.net",
  //URL : "https://ideationapi-prd.azurewebsites.net",
  Token: GetToken(),
  Status:{
    WaitApprove: "Waiting For Approval",
    WaitEdit: "Waiting For Review",
  }
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  SetURL(state, new_url) {
    state.URL = new_url
  },
  SetToken(state, new_token) {
    state.Token = new_token
  }
}

export default new Vuex.Store({
  state,
  mutations,
    actions: {

    },
    getters: {
      URL: state => state.URL,
      Token: stat => stat.Token,
      Status: state => state.Status
    }
})