import "core-js/stable";
import Vue from "vue";
import CoreuiVue from "@coreui/vue/src";
import App from "./App";
import router from "./router/index";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import UUID from "vue-uuid";

import * as XLSX from "xlsx/xlsx.mjs";
/* load 'fs' for readFile and writeFile support */
import * as fs from "fs";
XLSX.set_fs(fs);
/* load the codepage support library for extended support with older formats  */
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
XLSX.set_cptable(cpexcel);

Vue.use(CoreuiVue);
Vue.use(UUID);

// VueNotifications
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};
Vue.use(VueNotifications, options);
Vue.mixin({
  methods: {
    GetExtension(filename) {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    },

    CheckIsExcel(filename) {
      var ext = this.GetExtension(filename);
      switch (ext.toLowerCase()) {
        case "xls":
        case "xlsx":
          //etc
          return true;
      }
      return false;
    },

    validateFileImageExtention(fileExtension) {
      let imageExtension = ["Jpeg", "Png", "Jpg"];
      let imageFound = false;

      imageExtension.forEach(function (extension) {
        if (fileExtension.toLowerCase() == extension.toLowerCase()) {
          imageFound = true;
        }
        if (imageFound) {
          return;
        }
      });
      return imageFound;
    },
    OnExportToExcel(url, filename) {
      // let json = [
      //   { name: 'Dady', age: '21' },
      //   { name: 'Jonh', age: '25' },
      //   { name: 'James', age: '17' },
      // ]
      // const filename = name + '.xlsx'
      // const dataWS = XLSX.utils.json_to_sheet(arrayJson)
      // const wb = XLSX.utils.book_new()
      // XLSX.utils.book_append_sheet(wb, dataWS)
      // XLSX.writeFile(wb,filename)

      var a = document.createElement("a");
      a.href = url;
      a.style = "display: none";
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
    },
    CheckContentType(fileExtension) {
      let temp = null;
      switch (fileExtension) {
        case "doc":
          temp = "application/msword";
          break;
        case "docx":
          temp =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case "gif":
          temp = "image/gif";
          break;
        case "jpeg":
          temp = "image/jpeg";
          break;
        case "jpg":
          temp = "image/jpeg";
          break;
        case "png":
          temp = "image/png";
          break;
        case "pdf":
          temp = "application/pdf";
          break;
        case "ppt":
          temp = "application/vnd.ms-powerpoint";
          break;
        case "pptx":
          temp =
            "application/vnd.openxmlformats-officedocument.presentationml.presentation";
          break;
        case "xls":
          temp = "application/vnd.ms-excel";
          break;
        case "xlsx":
          temp =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
        case "txt":
          temp = "text/plain";
          break;
      }

      return temp;
    },
    CheckOpenFile(file, isExist = false) {
      let result = { isImage: false, urlImage: null };
      if (file == null) {
        return result;
      }

      let urlImage = null;
      let fileExtension = null;

      if (isExist) {
        const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(file.file, file.contentType);
        urlImage = URL.createObjectURL(blob);
        fileExtension = file.filename.split(".").pop();
      } else {
        urlImage = URL.createObjectURL(file);
        fileExtension = file.name.split(".").pop();

        // if (this.files.findIndex((f) => f.name == filename) > -1 ){
        //   const file = this.files[this.files.findIndex((f) => f.name == filename)];
        //   urlImage = URL.createObjectURL(file);
        //   fileExtension = file.name.split('.').pop();
        // }
      }

      if (this.validateFileImageExtention(fileExtension)) {
        result.isImage = true;
        result.urlImage = urlImage;
      } else {
        window.open(urlImage, "_blank");
      }

      return result;
    },
  },
});
// VueNotifications

Vue.mixin({
  methods: {
    $_toast_Noti(type, title, message) {
      return miniToastr[type](message, title, 7000);
    },
  },
});

new Vue({
  el: "#app",
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
